import PageForDogOwners from '../components/PageForDogOwners'
import { graphql } from 'gatsby'

export const query = graphql`
  query PageForDogOwners {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default PageForDogOwners
